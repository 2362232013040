body {
    background-color: #282c33;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
}

.App {
    text-align: center;
}

.App-logo {
    height: 20vmin;
    pointer-events: none;
    filter: invert(86%) sepia(12%) saturate(1477%) hue-rotate(327deg) brightness(101%) contrast(101%);
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 20vh;
    /*display: flex;*/
    /*flex-direction: column;
      font-size: calc(10px + 2vmin);
    */
    align-items: center;
    justify-content: center;
    color: white;
}

.App-body {
    background-color: #282c34;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

footer {
    height: 10vh;
    position: absolute;
    bottom: 0;
    width: 100%;
    
    
    padding-bottom: 20px;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


